.body {
    flex-grow: 1;
    flex-basis: 0;
    margin: 4rem 1rem 0 1rem;
    filter: drop-shadow(0 0 10px #0c1719);
    border-radius: 1.8rem;
    position: relative;
}

.name {
    font-size: 1.6rem;
    padding: 1rem 0;
    background-color: #5B4B49;
    border-top-left-radius: 1.8rem;
    border-top-right-radius: 1.8rem;
    color: #ECEBE4;
}

@media (min-width: 1500px) {
    .name {
        font-size: 2.4rem;
    }
}

.body input {
    outline: none;
    font-size: 1.6rem;
    text-align: center;
    background-color: #ffffff;
    width: 40%;
    color: black;
    border: none;
    border-radius: 4px;
    margin-top: -0.1rem;
    margin-bottom: -0.1rem;
}

.name > input:focus {
    background-color: #7c6f6e;
}

.balance {
    background-color: #C4A381;
    color: black;
    font-size: 2.4rem;
    padding: 0.5rem 0;
    border-bottom-left-radius: 1.8rem;
    border-bottom-right-radius: 1.8rem;
    width: 100%;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
}

@media (min-height: 730px) {
    .balance {
        padding: 2rem 0;
        font-size: 3.6rem;
    }
}

@media (min-height: 850px) {
    .balance {
        padding: 2rem 0;
    }
}

@media (min-height: 930px) {
    .balance {
        padding: 3rem 0;
        font-size: 4.8rem;
    }
}

.balanceSelected {
    background-color: #e9e9e9;
}

.deleteIcon {
    position: absolute;
    right: 1.4rem;
    top: 1.4rem;
    width: 2rem;
    height: 2rem;
    cursor: pointer;
}

.deleteIcon:hover {
    opacity: 1;
}

input.editingFontSize {
    font-size: 1.6rem;
    width: 80%;
    max-width: 30rem;
}

@media (min-height: 730px) {
    input.editingFontSize {
        font-size: 3.6rem;
    }
}

@media (min-height: 930px) {
    input.editingFontSize {
        font-size: 4.8rem;
    }
}

