.dragimage {
    background-color: #5B8C5A;
    border: 3px solid darkgreen;
    color: white;
    position: absolute;
    text-align: center;
    line-height: 8rem;
    font-size: 3.8rem;
    width: 20rem;
    height: 8rem;
    box-shadow: 5px 10px 5px 5px rgba(0, 0, 0, 0.3);
}

.dragimage::before {
    display: block;
    content: "";
    position:absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    border: 3px solid #e3e3e3;

}