.overview {
    background-color: #104547;
    color: whitesmoke;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 1rem;
    text-align: center;
    overflow: hidden;
}

.overview__players {
    display: flex;
    align-items: stretch;
    width: 100%;
    justify-content: space-evenly;
}
.overview__companies {
    display: flex;
    align-items: stretch;
    width: 100%;
    justify-content: space-evenly;
}

.overview__buttonRow {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 3rem;
    margin-right: 1rem;
}

