.multiplier {
    display: flex;
    justify-content: space-evenly;
    margin-top: 3rem;
    margin-left: 1rem;
    margin-right: 1rem;
    gap: 2rem;
}

.multiplier__selector {
    position: relative;
    box-sizing: border-box;
    flex-grow: 1;
    flex-basis: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    color: black;
    font-weight: 500;
    filter: drop-shadow(0 0 10px #0c1719);
    background-image: url('./gold-coin.svg');
    background-size: cover;
    background-position: center;
}

.multiplier__selector:before {
    content: "";
    display: block;
    padding-top: 100%;
}

.multiplier__selector--selected {
    transform: scale(1.04);
    background-image: url('./gold-coin-selected.svg');
}

.multiplier__selector:hover {
    transform: scale(1.04);
}

.multiplier__number {
    font-size: 3.6rem;
}

.multiplier__number--small {
    font-size: 3.2rem;
}

.multiplier__number--very-small {
    font-size: 2.4rem;
}

@media screen and (min-width: 1600px) {

    .multiplier__number {
        font-size: 4.8rem;
    }

    .multiplier__number--small {
        font-size: 4.2rem;
    }

    .multiplier__number--very-small {
        font-size: 3.2rem;
    }

    .multiplier__selector {
        border-width: 12px;
    }
}


