.configButton {
    border: 2px solid white;
    border-radius: 2rem;
    color: white;
    padding: 1rem 2rem;
    font-size: 2.4rem;
    cursor: pointer;
    background-color: #104547;
    margin-left: 2rem;
    filter: drop-shadow(0 0 10px #0c1719);
}

.buttonRow {
    margin: 2rem 0;
}

.done {
    width: 40rem;
    height: 20rem;
    background-color: #104547;
    border: 4px solid white;
    border-top-left-radius: 1.8rem;
    position: fixed;
    right: 0;
    bottom: 0;
    z-index: 2;
}

